.whitespace-nowrap {
  white-space: nowrap;
}
.table-header {
  color: #030054!important;
  font-family: 'Montserrat'!important;
  font-size: 16px!important;
  .Mui-active {
    color: #030054!important;
  }
}