a {
    text-decoration: none;
}

:focus-visible {
    outline: none;
}

.btn {
    transition: none !important;
}

.uppercase {
    text-transform: uppercase;
}

.flex-custom-center {
    display: flex;
    justify-content: center;
    align-items: center;
}


/*noAvaliableCompare*/
.noAvaliableCompareContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.noAvaliableCompareContainer>.image_container {
    width: 40%;
}

.noAvaliableCompareContainer>.text_container {
    width: 60%;
}

.compareOffersExcitedFamily_image {
    height: auto;
    width: 100%;
}

.noAvaliableCompareContainer h1 {
    color: #7e106e;
}

.noAvaliableCompareContainer p {
    color: #030054;
}

.noAvaliableCompareContainer p.text-blue {
    color: #02b4d8;
}

@media only screen and (max-width: 900px) {
    .noAvaliableCompareContainer {
        display: block;
        width: 100%;
    }

    .noAvaliableCompareContainer>.image_container {
        width: 100%;
    }
    
    .noAvaliableCompareContainer>.text_container {
        width: 100%;
    }
}