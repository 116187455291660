.nav-component-container {
    display: flex;
    justify-content: center;
    background-color: #59B6D5;
    font-family: "Inter" !important;
}

.nav-router {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 100%;
}

.nav-flex {
    display: flex;
    align-items: center;
    height: 100%;
}

.road2CollegeIcon__box {
    background-color: black;
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-router .nav-flex .road2CollegeIcon__box .road2CollegeIcon {
    width: auto;
    height: 24px;
}

.nav-router .nav-link-box {
    padding: 0 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-router .custom-nav {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: black;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Inter';
}

.dropdown-insights.dropdown-insights-support .dropdown-item, .dropdown-insights.dropdown-profile .dropdown-item {
    font-size: 14px;
}

.nav-router .nav-link-box .nav-link {
    font-weight: 700;
    font-size: 14px;
    color: black;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Inter';
    transition: none;
}

.mr-4 {
    margin-right: 2rem;
}

.nav-link-box.active {
    background-color: #7e106e;
}

.nav-link-box:hover, .nav-link-box:hover .nav-link {
    background-color: #7e106e;
    color: #fff;
}

.nav-link-box.active .nav-link {
    color: #fff;
}

.nav-router .nav-link-button .btn-get-started {
    color: #ffffff;
    background: #7e106e;
    font-weight: 600;
    line-height: 1.3vw;
    white-space: nowrap;
    height: 30px;
    margin-left: 1rem;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    padding: 0 1rem;
}

.dropdown-menu.profile-dropdown {
    right: 0;
}

.nav-red {
    color: red;
}

.title-dropdown {
    font-size: 14px;
    font-weight: 700;
}

.dropdown-insights.dropdown.show {
    background-color: #7e106e;
    color: #fff;
    height: 100%;
}

.dropdown-insights.dropdown.show .dropdown-toggle {
    background-color: #7e106e;
    color: #fff;
    height: 100%;
}

.dropdown-insights>.dropdown-menu>.dropdown-item {
    padding: 1rem;
    display: flex;
    justify-content: end;
}

.dropdown-insights .dropdown-toggle {
    height: 100%;
    padding: 0 1rem;
}

.dropdown-insights>.dropdown-menu, .dropdown-insights-support>.dropdown-menu {
    top: 39px !important;
    transform: translate(1px) !important;
    padding: 0;
    background-color: #7e106e;
    border-radius: 0;
}

.dropdown-insights.dropdown, .dropdown-custom-header>.dropdown-toggle {
    height: 100%;
}

.dropdown-insights-support>.dropdown-menu {
    transform: translate(-48px) !important;
}

.dropdown-insights>.dropdown-menu>.dropdown-item, .dropdown-insights>.dropdown-menu>.dropdown-item:hover {
    color: #fff;
    background-color: #7e106e;
}

.dropdown-bg-none.text-black>button {
    color: #000;
}

.support-container-menu.active.loggued-out {
    right: calc(370px - 3vw);
}

.mb-4 {
    margin-bottom: 1rem;
}

.profile-button-wrapper {
    display: flex;
    justify-content: center;
    height: 40px;
    width: 40px;
}

.profile-menu {
    padding: 1rem;
    display: none;
    background: #7e106e;
    position: absolute;
    width: max-content;
    top: 40px;
    z-index: 1001;
    transition: 0.3s ease-in-out all;
    font-family: "Source Sans Pro";
}

.profile-menu.active {
    display: block;
    right: 0;
}

.profile-menu.active.submenu-active {
    right: calc(370px - 3vw);
}

.profile-button-wrapper.active {
    background-color: #7e106e;
}

.profile-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.profile-user {
    width: 16px;
    object-fit: contain;
}

.profile-menu .link-container {
    display: flex;
    justify-content: end;
    font-family: "Source Sans Pro";
}

.profile-menu .link-container .link {
    color: white;
    text-decoration: none;
    font-size: 14px;
}

.profile-menu-arrow {
    margin-left: 0.5rem;
    height: 12px;
}

/*Mobile*/
.nav-mobile-noAuthenticated {
    display: flex;
}


@media only screen and (max-width: 900px) {
    .nav-mobile-noAuthenticated {
        display: none;
    }

    .nav-router {
        justify-content: start;
    }
}

@media only screen and (max-width: 512px) {
    .header-container, .nav-component-container {
        padding: 0;
    }
}